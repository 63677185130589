<template>
    <div class="row">
        <div class="d-flex align-content-center flex-wrap w-100">
            <div class="col-sm-8 px-1 my-1">
                <div role="group">
                    <b-form-input
                            id="input-invoice"
                            v-model="invoice"
                            :state="validInput"
                            aria-describedby="input-invoice-feedback"
                            v-bind:placeholder="$t('enter_invoice_number')"
                            v-on:keyup.enter="trace"
                            v-on:input="cancelPhoneInput"
                            v-on:blur="bluredInvoice = true"
                    ></b-form-input>

                    <!-- This will only be shown if the preceding input has an invalid state -->
                    <b-form-invalid-feedback id="input-invoice-feedback">
                        {{ $t('required') }}
                    </b-form-invalid-feedback>
                </div>

            </div>
            <div class="col-sm-4 px-1 my-1">
                <b-button variant="success" :disabled="btnDisabled" class="w-100 m-0" @click="trace">{{ $t('search') }}</b-button>
            </div>

        </div>
        <div class="w-100" v-show="show_receiver_phone">
            <div class="d-flex align-content-center flex-wrap w-100">
                <div class="col-sm-8 px-1 my-1">

                    <div role="group">
                        <b-form-input
                                id="input-phone"
                                maxlength="4"
                                v-model="receiver_phone"
                                :state="validPhone"
                                aria-describedby="input-phone-feedback"
                                v-bind:placeholder="$t('last_four_digits')"
                                v-on:keyup.enter="trace"
                                v-on:blur="bluredPhone = true"
                        ></b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-phone-feedback">
                            {{ $t('required') }}
                        </b-form-invalid-feedback>
                    </div>

                </div>
                <div class="col-sm-4 px-1 my-1 d-flex align-self-center justify-content-center">
                    <b-button variant="success" class="btn-circle" @click="trace">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" width="20px" height="20px">
                            <path fill="#fff" d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'></path>
                        </svg>
                    </b-button>
                    <b-button variant="danger" class="btn-circle" @click="cancelPhoneInput">
                        <svg xmlns="http://www.w3.org/2000/svg" fill='#fff' viewBox="-2 -2 7 7" width="20px" height="20px">
                            <path stroke='#fff' d='M0 0l3 3m0-3L0 3'/><circle r='.5'/><circle cx='3' r='.5'/><circle cy='3' r='.5'/><circle cx='3' cy='3' r='.5'/>
                        </svg>

                    </b-button>
                </div>
            </div>
        </div>
        <div class="d-flex align-content-center flex-wrap w-100 mt-2">
            <b-table striped bordered responsive hover
                     show-empty
                     :items="statuses"
                     :fields="fields"
                     :empty-text="noDataText"
                     v-show="showTable"
                     thead-class="hidden_header">
                <template slot="empty" slot-scope="scope" class="text-center">
                    {{ scope.emptyText }}
                </template>

            </b-table>
        </div>

    </div>
</template>

<script>
    /* eslint-disable no-undef,no-console,no-unused-vars */
    import { bus } from './../events'
    export default {
        name: "Tracing",
        data() {
            return {
                // host: 'http://backend.yii2-starter-kit.localhost:81/tracking/track',
                host: 'https://api-marketing.cdek.ru/admin/tracking/track',
                invoice: "",
                receiver_phone: "",
                show_receiver_phone: false,
                showTable: false,
                noDataText: this.$t('no_results'),
                btnDisabled: false,
                error: false,
                bluredInvoice: false,
                bluredPhone: false,
                receiverPhoneError: false,
                fields: ['cityName', 'date', 'statusName'],
                statuses: [],

            };
        },
        computed: {
            validInput() {
                if (!this.bluredInvoice ) return null;
                return this.invoice != '' ? true : false
            },
            validPhone() {
                if (!this.bluredPhone ) return null;
                return /^\d{4}$/.test(this.receiver_phone);
            }
        },
        props: {
            lang: String,
        },
        methods: {
            trace: function(event) {
                if (this.invoice) {
                    bus.$emit('trace-step', 'click-tracebtn-not-empty-num')
                    this.noDataText = this.$t('no_results')
                    this.showTable = false
                    this.statuses = []
                    this.btnDisabled = true

                    let url = this.host;
                    let data = {
                        "enableTrace": true,
                        "lang": this.lang,
                        "orderNumber": this.invoice, //"1091202878"
                    }

                    if (this.show_receiver_phone && this.receiver_phone) {
                        data['numbersReceiverPhone'] = this.receiver_phone
                    }


                  axios.post(url, data)
                    // eslint-disable-next-line
                        .then(response => {
                            if (response.status === 200) {
                                bus.$emit('trace-step', 'click-tracebtn-success-result')
                              var tmp = new Array();
                              for(var i = 0; i < response.data.result.statuses.length; i++){
                                tmp[i] = new Object();
                                if(response.data.result.statuses[i].currentCity){
                                  tmp[i]['cityName'] = response.data.result.statuses[i].currentCity.name;
                                }
                                else{
                                  tmp[i]['cityName'] = '';
                                }
                                if(response.data.result.statuses[i].timestamp){
                                  tmp[i]['date'] = new Date(response.data.result.statuses[i].timestamp).toLocaleString(undefined, {year: 'numeric',   month: 'numeric', day: 'numeric'});
                                }
                                else{
                                  tmp[i]['date'] = '';
                                }

                                if(response.data.result.statuses[i].name){
                                  tmp[i]['statusName'] = response.data.result.statuses[i].name;
                                }
                                else if(response.data.result.statuses[i].code){
                                  tmp[i]['statusName'] = response.data.result.statuses[i].code;
                                }
                                else{
                                  tmp[i]['statusName'] = '';
                                }
                              }
                              this.statuses = tmp;

                            }
                            this.btnDisabled = false
                            this.showTable = true
                            //this.show_receiver_phone = false
                        })
                        .catch(error => {

                            bus.$emit('trace-step', 'click-tracebtn-failed-result')
                            var html = '';
                            this.btnDisabled = false
                            if (error.response) {
                                if (error.response.data.alerts != undefined) {
                                    for (var i in error.response.data.alerts) {
                                        html += error.response.data.alerts[i].msg + '   '
                                    }
                                    this.noDataText = html
                                    if (error.response.data.alerts[i].errorCode == "cant_determine_order_without_phone") {
                                        this.show_receiver_phone = true
                                        this.receiver_phone = ''
                                    }
                                }
                            } else if (error.request) {
                                // console.log(error.request)
                            } else {
                                // console.log("Error", error.message);
                            }
                            if (html == '')
                                this.noDataText = this.$t('invoice_search_error')
                            this.showTable = true
                        });
                } else {
                    bus.$emit('trace-step', 'click-tracebtn-empty-num')
                    this.bluredInvoice = true
                    this.error = true
                    // console.log('пустой')
                }

            },
            checkPhone: function(event) {
                if (this.invoice && this.receiver_phone) {
                    this.noDataText = this.$t('no_results')
                    this.showTable = false
                    this.statuses = []
                    this.btnDisabled = true

                    let url = this.host;

                    let data = {
                        "enableTrace": true,
                        "lang": this.lang,
                        "orderNumber": this.invoice,
                        'numbersReceiverPhone': this.receiver_phone
                    }

                    // console.log(url, data)
                  axios.post(url + '?step=2', data)
                        .then(response => {
                            // console.dir("сделать проверку на статус");
                            // console.dir("ответ", response.data);
                            if (response.status == 200) {
                                this.cancelPhoneInput()
                                this.trace()
                            }
                            this.btnDisabled = false

                        })
                        .catch(error => {
                            var html = '';
                            console.dir(error.response.status);
                            if (error.response) {
                                if (error.response.data.alerts != undefined) {
                                    for (var i in error.response.data.alerts) {
                                        html += error.response.data.alerts[i].msg + '   '
                                    }
                                    this.noDataText = html
                                }
                            }
                            if (html == '')
                                this.noDataText = this.$t('invoice_search_error')
                            this.showTable = true
                            this.btnDisabled = false

                        });
                } else {
                    this.receiverPhoneError = true
                }
            },
            cancelPhoneInput: function(event) {
                this.receiver_phone = ''
                this.receiverPhoneError = false
                this.show_receiver_phone = false
                this.showTable = false
                this.statuses = []
            },

            showlist: function(list){

            }

        }
    };
</script>

<style>
    .hidden_header {
        display: none;
    }

    .btn-circle{
        width: 2rem;
        height: 2rem;
        border-radius: 1rem!important;
        font-size: 1rem!important;
        line-height: 1!important;
        padding: 0!important;
        margin: 0 1rem;
    }
</style>
