import Vue from 'vue'
import BSpinner from 'bootstrap-vue/es/components/spinner/spinner'
Vue.component('b-spinner', BSpinner)

import BButton from 'bootstrap-vue/es/components/button/button'
Vue.component('b-button', BButton)

import BFormInput from 'bootstrap-vue/es/components/form-input/form-input'
Vue.component('b-form-input', BFormInput)

import BFormInvalidFeadback from 'bootstrap-vue/es/components/form/form-invalid-feedback'
Vue.component('b-form-invalid-feedback', BFormInvalidFeadback)

import BFormText from 'bootstrap-vue/es/components/form/form-text'
Vue.component('b-form-text', BFormText)

import BTable from 'bootstrap-vue/es/components/table/table'
Vue.component('b-table', BTable)