<template>
  <div id="app">
    <div class="container position-relative">
      <Tracing v-bind:lang="lang"/>
      <Spinner v-show="spinnerVisible"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import i18n from './i18n'
import {bus} from './events'
import './plugins/bootstrap-vue'
import './plugins/axios'
import Tracing from './components/Tracing'
import Spinner from './components/Spinner'

export default {
  i18n,
  name: 'App',
  components: {
    Tracing,
    Spinner
  },
  props: {
    appLang: String,
  },
  data() {
    return {
      langs: {
        'ru': 'rus',
        'en': 'eng',
        'cz': 'cze',
        'mn': 'mng',
        'lt': 'ltu',
        'de': 'deu',
        'ae': 'are',
        'tr': 'tur',
        'kr': 'kor',
      },

      spinnerVisible: false,
    }
  },
  computed: {
    lang: function () {
      return (this.appLang in this.langs) ? this.langs[this.appLang] : 'eng';
    }
  },

  methods: {
    showSpinner: function () {
      this.spinnerVisible = true
    },
    hideSpinner: function () {
      this.spinnerVisible = false
    },
    setLang: function () {
      this.$i18n.locale = (this.appLang in this.langs) ? this.langs[this.appLang] : 'eng';
    },
    raiseEvent(e) {
      this.$emit(e);
    }
  },
  beforeCreate: function () {

  },
  created: function () {
    bus.$on('before-request', this.showSpinner)
    bus.$on('request-error', this.hideSpinner)
    bus.$on('after-response', this.hideSpinner)
    bus.$on('response-error', this.hideSpinner)
    bus.$on(['trace-step'], (e) => {
      this.raiseEvent(e)
    })
  },
  beforeDestroy: function () {
    bus.$off('before-request', this.showSpinner)
    bus.$off('request-error', this.hideSpinner)
    bus.$off('after-response', this.hideSpinner)
    bus.$off('response-error', this.hideSpinner)
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.min.css';

#app {
  padding: 1rem;
}

</style>
